import { memo, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { MenuItem } from "primereact/menuitem";

import { useAppSelector, useAppDispatch, setOption, setFooterContent, setHeaderContent } from "utils/store";
import { SettingsKey, TDefectListOption } from "models";

import { MiniTiptap } from "pages/tip-tap-editor";
import { saveTemplate } from "utils/api";
import { SidebarMenu } from "utils/ui";

import packageJson from "../../../../../package.json";

const MiniTipTapMemo = memo(MiniTiptap);

const ContentsPageTemplate = (_: ContentsPageTemplateProps) => {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.templateBuilderSlice.defaultOptions);
  const headerInitContent = useAppSelector((state) => state.templateBuilderSlice.headerEditorInit);
  const footerInitContent = useAppSelector((state) => state.templateBuilderSlice.footerEditorInit);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // hack to avoid tip tap editors from gaining focus and
    // autoscrolling the screen to undesired location
    setTimeout(() => {
      setIsReady(true);
    }, 50);
  }, []);

  const doDebouncedSave = useDebouncedCallback(async () => dispatch(saveTemplate()), 3000, {
    leading: false,
    trailing: true,
  });

  const breadcrums: MenuItem[] = [
    { label: "Template Builder" },
    { label: "General Settings", className: "breadcrumb-active-element" },
  ];

  const onChange = (e: any) => {
    if (!options[SettingsKey.ContentsPageTitle]) return;
    const option: TDefectListOption = { ...options[SettingsKey.ContentsPageTitle], value: e.target.value };
    dispatch(setOption({ key: SettingsKey.ContentsPageTitle, option }));
    doDebouncedSave();
  };

  const onHeaderChange = (e: any) => {
    dispatch(setHeaderContent(e));
    doDebouncedSave();
  };

  const onFooterChange = (e: any) => {
    dispatch(setFooterContent(e));
    doDebouncedSave();
  };

  return (
    <div className="p-5 h-full classy-scrollbar overflow-auto">
      <div style={{ minHeight: "1200px" }} className="flex flex-col items-center">
        <BreadCrumb className="mb-5" style={{ width: "700px", maxWidth: "90vw" }} model={breadcrums} />

        <div className="p-card p-5" style={{ width: "700px", maxWidth: "90vw" }}>
          <h1>General Settings</h1>

          <SidebarMenu.Divider />
          <SidebarMenu.Heading title="Contents Page" />

          <p>Set the contents table title.</p>
          <InputText
            value={`${options[SettingsKey.ContentsPageTitle]?.value}`}
            required
            maxLength={40}
            onChange={onChange}
          />

          <SidebarMenu.Divider />
          <SidebarMenu.Heading title="Headers & Footers" />
          <p>Set the header and footer content on all pages after the cover page.</p>

          {/** additional divs for optimising scrolling and screen jump */}
          <div style={{ minHeight: "300px" }}>
            <SidebarMenu.Heading title="Header Content" />
            <div style={{ display: isReady ? "" : "none" }}>
              <MiniTipTapMemo toolbarHeight={0} initialData={headerInitContent} onChange={onHeaderChange} />
            </div>
          </div>

          <div style={{ minHeight: "300px" }}>
            <SidebarMenu.Heading title="Footer Content" />
            <p>Page numbers are displayed automatically in the bottom left corner.</p>
            <div style={{ display: isReady ? "" : "none" }}>
              <MiniTipTapMemo toolbarHeight={0} initialData={footerInitContent} onChange={onFooterChange} />
            </div>
          </div>

          <div className="text-sm text-center mt-5">Version {packageJson.version}</div>
        </div>
      </div>
    </div>
  );
};

interface ContentsPageTemplateProps {}

export default ContentsPageTemplate;
