import { setDocumentId, setSaving, AppDispatch, RootState } from "utils/store";
import { postTemplate } from "utils/api";
import { environment } from "environments/environment";

/**
 * Post data to api and update redux store
 * @param dispatch
 */
export const saveTemplate = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const storeState = getState();

    const { pages, defaultOptions, settings, documentId, templateName, isSaving, loading, version } =
      storeState.templateBuilderSlice;

    // don't save if already saving or is loading
    if (loading || isSaving) {
      return;
    } else {
      dispatch(setSaving(true));
    }

    if (!environment.production) {
      console.log("SAVING", { pages, defaultOptions, settings });
    }
    
    if (environment.enabled) {
      const result = await postTemplate(templateName, { id: documentId, pages, defaultOptions, settings, version });

      if (result) {
        dispatch(setDocumentId(result));
      }
    }

    dispatch(setSaving(false));
  };
};
