import "./LoadingController.scss";

import { memo } from "react";
import { BlockUI } from "primereact/blockui";
import { LoaderCircle } from "lucide-react";

const LoaderWidget = ({ message }: _LoaderWidgetProps) => {
  return (
    <div className="loader-widget p-2 flex flex-row items-center justify-center">
      <div className="p-2 spinner">
        <LoaderCircle size={38} color="var(--text-color)" />
      </div>
      {message !== "" && <span className="ml-3 mr-2 leading-5">{message}</span>}
    </div>
  );
};

const AppLoadingController = ({ loading, message }: _LoadingControllerProps) => {
  return <BlockUI fullScreen={true} blocked={loading} template={<LoaderWidget message={message ?? ""} />} />;
};

interface _LoadingControllerProps {
  loading: boolean;
  message?: string;
}

interface _LoaderWidgetProps {
  message: string;
}

export const LoadingController = memo(AppLoadingController);
