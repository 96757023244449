import { memo, useCallback, useEffect, useRef, useState } from "react";
import { RefreshCcw, X } from "lucide-react";

import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

import { kToolbarHeight } from "models";
import { getPreview } from "utils/api";
import { LoadingController } from "utils/ui";

const MemoButton = memo(Button);

export const PdfPreview = (): JSX.Element => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(false);

  const load = useCallback(async (): Promise<void> => {
    setLoading(true);
    const { buffer } = await getPreview();
    if (buffer) {
      // convert buffer to dataurl
      const blob = new Blob([buffer], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isMounted.current) load();

    return () => {
      isMounted.current = true;
    };
  }, [load]);

  const start = (
    <div className="flex flex-row items-center gap-4">
      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Template Preview</span>
    </div>
  );

  const end = (
    <div className="mr-4">
      <MemoButton
        label="Refresh"
        tooltip="Refresh Preview"
        data-pr-position="bottom"
        aria-label="Refresh Preview"
        severity="info"
        className="mr-4"
        onClick={async () => {
          setPdfUrl("");
          await load();
        }}
      >
        <span className="ml-2"></span>
        <RefreshCcw size={18} />
      </MemoButton>

      <MemoButton
        label="Close Preview"
        tooltip="Close tab"
        data-pr-position="bottom"
        aria-label="Close tab"
        severity="info"
        className="mr-4"
        onClick={async () => {
          window.close();
        }}
      >
        <span className="ml-2"></span>
        <X size={18} />
      </MemoButton>
    </div>
  );

  return (
    <>
      <div className="bg-neutral-600" style={{ height: "100vh", width: "100%" }}>
        <Toolbar
          className="p-0 pl-4 shadow-md"
          style={{ height: `${kToolbarHeight}px`, borderRadius: "0px" }}
          start={start}
          end={end}
        ></Toolbar>

        <div style={{ height: `calc(100vh - ${kToolbarHeight}px)` }}>
          {pdfUrl && (
            <object data={pdfUrl} type="application/pdf" aria-label="PDF Preview" width="100%" height="100%"></object>
          )}
        </div>
      </div>

      <LoadingController loading={loading} message="Generating, please wait." />
    </>
  );
};
